/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////
import FBLogo from './Social_media_logos/facebookLogo.png';
import instaLogo from './Social_media_logos/instaLogo.png';
import pinterslogo from './Social_media_logos/pinterslogo.png';
import tiktokLogo from './Social_media_logos/tiktokLogo.png';
import utubeLogo from './Social_media_logos/utubeLogo.png';
import weblogo from '../config/Social_media_logos/weblogo.png';
// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */

export const workingAnimalListOptions = [
  { option: 'dog', label: 'Dogs' },
  { option: 'cat', label: 'Cats' },
  { option: 'cattle', label: 'Cattle' },
  { option: 'rabbit', label: 'Rabbits' },
  { option: 'turtleTortoises', label: 'Turtle / Tortoises' },
  { option: 'rodents', label: 'Rodents' },
  { option: 'fishAmphibians', label: 'Fish / Amphibians' },
  { option: 'horseDonkeyMule', label: 'Horse / Donkey /Mule' },
  { option: 'goatsSheep', label: 'Goats Sheep' },
  { option: 'bird', label: 'Birds' },
  { option: 'pig', label: 'Pigs' },
  { option: 'reptiles', label: 'Reptiles' },
  { option: 'others', label: 'Others' },
];

export const traitsOptions = [
  {
    option: 'personOfColor',
    label: 'Person of Color',
    key: 'personOfColor',
  },
  {
    option: 'lgbtqia+',
    label: 'LGBTQIA+',
    key: 'lgbtqia+',
  },
  {
    option: 'rescueGroup501c3',
    label: 'Rescue Group 501(c)3',
    key: 'rescueGroup501c3',
  },
  {
    option: 'multiAnimalHome',
    label: 'Multi Animal Home',
    key: 'multiAnimalHome',
  },
  {
    option: 'singleAnimalHome',
    label: 'Single Animal Home',
    key: 'singleAnimalHome',
  },
  {
    option: 'accountOnlyShowsPets',
    label: 'Account that only shows pets',
    key: 'accountOnlyShowsPets',
  },
  {
    option: 'accountShowsPeopleAndPets',
    label: 'Account that shows people and pets',
    key: 'accountShowsPeopleAndPets',
  },
  {
    option: 'accountShowsKidsPeopleAndPets',
    label: 'Account that shows kids, people, and pets',
    key: 'accountShowsKidsPeopleAndPets',
  },
  {
    option: 'breederAnimal',
    label: 'Breeder Animal',
    key: 'breederAnimal',
  },
  {
    option: 'serviceAnimal',
    label: 'Service Animal',
    key: 'serviceAnimal',
  },
  {
    option: 'rescuedAnimal',
    label: 'Rescued animal',
    key: 'rescuedAnimal',
  },
  {
    option: 'specialNeedsAnimal',
    label: 'Special needs animal',
    key: 'specialNeedsAnimal',
  },
  {
    option: 'groomer',
    label: 'Groomer',
    key: 'groomer',
  },
  {
    option: 'petNutritionist',
    label: 'Pet Nutritionist',
    key: 'petNutritionist',
  },
  {
    option: 'vetOrVetTech',
    label: 'Vet or Vet tech',
    key: 'vetOrVetTech',
  },
  {
    option: 'animalTrainer',
    label: 'Animal trainer',
    key: 'animalTrainer',
  },
  {
    option: 'beautifulModelAnimal',
    label: 'Beautiful model animal',
    key: 'beautifulModelAnimal',
  },
  {
    option: 'scaryLookingIntimidating',
    label: 'Scary looking or intimidating',
    key: 'scaryLookingIntimidating',
  },
  {
    option: 'cute',
    label: 'Cute',
    key: 'cute',
  },
  {
    option: 'uniqueFunnyLookingHairless',
    label: 'Unique, funny looking, or hairless',
    key: 'uniqueFunnyLookingHairless',
  },
  {
    option: 'fluffy',
    label: 'Fluffy',
    key: 'fluffy',
  },
  {
    option: 'athleticBuild',
    label: 'Athletic build',
    key: 'athleticBuild',
  },
  {
    option: 'familyFriendlyLook',
    label: 'Family friendly look',
    key: 'familyFriendlyLook',
  },
  {
    option: 'therapyDogs',
    label: 'Therapy Dogs',
    key: 'therapyDogs',
  },
  {
    option: 'equineAssistedTherapy',
    label: 'Equine Assisted Therapy',
    key: 'equineAssistedTherapy',
  },
  {
    option: 'animalAssistedTherapyActivities',
    label: 'Animal Assisted Therapy & Activities',
    key: 'animalAssistedTherapyActivities',
  },
  {
    option: 'demonstrationsWorkshops',
    label: 'Demonstrations and Workshops',
    key: 'demonstrationsWorkshops',
  },
  {
    option: 'wildlifeEducationAmbassadorConservationPrograms',
    label: 'Wildlife Education Ambassador & Conservation Programs',
    key: 'wildlifeEducationAmbassadorConservationPrograms',
  },
  {
    option: 'animalInteractionPettingZoo',
    label: 'Animal Interaction (Petting Zoo)',
    key: 'animalInteractionPettingZoo',
  },
  {
    option: 'ponyRides',
    label: 'Pony Rides',
    key: 'ponyRides',
  },
  {
    option: 'trickAnimalShows',
    label: 'Trick Animal Shows',
    key: 'trickAnimalShows',
  },
  {
    option: 'animalAssistedYoga',
    label: 'Animal Assisted Yoga',
    key: 'animalAssistedYoga',
  },
  {
    option: 'animalHandlers',
    label: 'Animal Handlers',
    key: 'animalHandlers',
  },
  {
    option: 'animalCaretakersWalkers',
    label: 'Animal Caretakers and Walkers',
    key: 'animalCaretakersWalkers',
  },
  {
    option: 'animalNutritionists',
    label: 'Animal Nutritionists',
    key: 'animalNutritionists',
  },
  {
    option: 'equineDentistsFarriers',
    label: 'Equine Dentists and Farriers',
    key: 'equineDentistsFarriers',
  },
  {
    option: 'animalWelfareInspectors',
    label: 'Animal Welfare Inspectors',
    key: 'animalWelfareInspectors',
  },
  {
    option: 'filmProductionWranglers',
    label: 'Film and Production Wranglers',
    key: 'filmProductionWranglers',
  },
  {
    option: 'animalBehaviorist',
    label: 'Animal Behaviorist',
    key: 'animalBehaviorist',
  },
  {
    option: 'animalTrainers',
    label: 'Animal Trainers',
    key: 'animalTrainers',
  },
  {
    option: 'animalCommunicators',
    label: 'Animal Communicators',
    key: 'animalCommunicators',
  },
  {
    option: 'wildlifeTransporters',
    label: 'Wildlife Transporters',
    key: 'wildlifeTransporters',
  },
  {
    option: 'petRelocationExperts',
    label: 'Pet Relocation Experts',
    key: 'petRelocationExperts',
  },
  {
    option: 'animalPhotographers',
    label: 'Animal Photographers',
    key: 'animalPhotographers',
  },
];

const traitsForAnimalContentCreator = [
  {
    option: 'personOfColor',
    label: 'Person of Color',
    key: 'personOfColor',
  },
  {
    option: 'lgbtqia+',
    label: 'LGBTQIA+',
    key: 'lgbtqia+',
  },
  {
    option: 'rescueGroup501c3',
    label: 'Rescue Group 501(c)3',
    key: 'rescueGroup501c3',
  },
  {
    option: 'multiAnimalHome',
    label: 'Multi Animal Home',
    key: 'multiAnimalHome',
  },
  {
    option: 'singleAnimalHome',
    label: 'Single Animal Home',
    key: 'singleAnimalHome',
  },
  {
    option: 'accountOnlyShowsPets',
    label: 'Account that only shows pets',
    key: 'accountOnlyShowsPets',
  },
  {
    option: 'accountShowsPeoplePets',
    label: 'Account that shows people and pets',
    key: 'accountShowsPeoplePets',
  },
  {
    option: 'accountShowsKidsPeoplePets',
    label: 'Account that shows kids, people and pets',
    key: 'accountShowsKidsPeoplePets',
  },
  {
    option: 'breederAnimal',
    label: 'Breeder Animal',
    key: 'breederAnimal',
  },
  {
    option: 'serviceAnimal',
    label: 'Service Animal',
    key: 'serviceAnimal',
  },
  {
    option: 'rescuedAnimal',
    label: 'Rescued animal',
    key: 'rescuedAnimal',
  },
  {
    option: 'specialNeedsAnimal',
    label: 'Special needs animal',
    key: 'specialNeedsAnimal',
  },
  {
    option: 'groomer',
    label: 'Groomer',
    key: 'groomer',
  },
  {
    option: 'petNutritionist',
    label: 'Pet Nutritionist',
    key: 'petNutritionist',
  },
  {
    option: 'vetOrVetTech',
    label: 'Vet or Vet tech',
    key: 'vetOrVetTech',
  },
  {
    option: 'animalTrainer',
    label: 'Animal trainer',
    key: 'animalTrainer',
  },
];

const allTraitsForFilter = [
  {
    key: 'beautifulModelAnimal',
    label: 'Beautiful model animal',
    option: 'beautifulModelAnimal',
  },
  {
    key: 'scaryLookingIntimidating',
    label: 'Scary looking or intimidating',
    option: 'scaryLookingIntimidating',
  },
  {
    key: 'cute',
    label: 'Cute',
    option: 'cute',
  },
  {
    key: 'uniqueFunnyLookingHairless',
    label: 'Unique, funny looking, or hairless',
    option: 'uniqueFunnyLookingHairless',
  },
  {
    key: 'fluffy',
    label: 'Fluffy',
    option: 'fluffy',
  },
  {
    key: 'athleticBuild',
    label: 'Athletic build',
    option: 'athleticBuild',
  },
  {
    key: 'familyFriendlyLook',
    label: 'Family friendly look',
    option: 'familyFriendlyLook',
  },
  {
    key: 'therapyDogs',
    label: 'Therapy Dogs',
    option: 'therapyDogs',
  },
  {
    key: 'equineAssistedTherapy',
    label: 'Equine Assisted Therapy',
    option: 'equineAssistedTherapy',
  },
  {
    key: 'animalAssistedTherapyActivities',
    label: 'Animal Assisted Therapy &amp; Activities',
    option: 'animalAssistedTherapyActivities',
  },
  {
    key: 'demonstrationsWorkshops',
    label: 'Demonstrations and Workshops',
    option: 'demonstrationsWorkshops',
  },
  {
    key: 'wildlifeEducationAmbassadorConservationPrograms',
    label: 'Wildlife Education Ambassador &amp; Conservation Programs',
    option: 'wildlifeEducationAmbassadorConservationPrograms',
  },
  {
    key: 'animalInteractionPettingZoo',
    label: 'Animal Interaction (Petting Zoo)',
    option: 'animalInteractionPettingZoo',
  },
  {
    key: 'ponyRides',
    label: 'Pony Rides',
    option: 'ponyRides',
  },
  {
    key: 'trickAnimalShows',
    label: 'Trick Animal Shows',
    option: 'trickAnimalShows',
  },
  {
    key: 'animalAssistedYoga',
    label: 'Animal Assisted Yoga',
    option: 'animalAssistedYoga',
  },
  {
    key: 'livestockGuardianDogs',
    label: 'Livestock Guardian Dogs',
    option: 'livestockGuardianDogs',
  },
  {
    key: 'personalProtectionDogs',
    label: 'Personal Protection Dogs',
    option: 'personalProtectionDogs',
  },
  {
    key: 'searchAndRescueDogs',
    label: 'Search and Rescue Dogs',
    option: 'searchAndRescueDogs',
  },
  {
    key: 'detectionDogs',
    label: 'Detection Dogs (Drugs, Explosives, Diseases)',
    option: 'detectionDogs',
  },
  {
    key: 'conservationWildlifeTrackingAnimals',
    label: 'Conservation Wildlife Tracking Animals',
    option: 'conservationWildlifeTrackingAnimals',
  },
  {
    key: 'grazingWeedManagementAnimal',
    label: 'Grazing and Weed Management Animal',
    option: 'grazingWeedManagementAnimal',
  },
  {
    key: 'pestControlAnimals',
    label: 'Pest Control Animals (Birds and Dogs) for Agriculture',
    option: 'pestControlAnimals',
  },
  {
    key: 'agriculturalManagement',
    label: 'Agricultural Management',
    option: 'agriculturalManagement',
  },
  {
    key: 'personOfColor',
    label: 'Person of Color',
    option: 'personOfColor',
  },
  {
    key: 'lgbtqia+',
    label: 'LGBTQIA+',
    option: 'lgbtqia+',
  },
  {
    key: 'rescueGroup501c3',
    label: 'Rescue Group 501(c)3',
    option: 'rescueGroup501c3',
  },
  {
    key: 'multiAnimalHome',
    label: 'Multi Animal Home',
    option: 'multiAnimalHome',
  },
  {
    key: 'singleAnimalHome',
    label: 'Single Animal Home',
    option: 'singleAnimalHome',
  },
  {
    key: 'accountOnlyShowsPets',
    label: 'Account that only shows pets',
    option: 'accountOnlyShowsPets',
  },
  {
    key: 'accountShowsPeoplePets',
    label: 'Account that shows people and pets',
    option: 'accountShowsPeoplePets',
  },
  {
    key: 'accountShowsKidsPeoplePets',
    label: 'Account that shows kids, people and pets',
    option: 'accountShowsKidsPeoplePets',
  },
  {
    key: 'breederAnimal',
    label: 'Breeder Animal',
    option: 'breederAnimal',
  },
  {
    key: 'serviceAnimal',
    label: 'Service Animal',
    option: 'serviceAnimal',
  },
  {
    key: 'rescuedAnimal',
    label: 'Rescued animal',
    option: 'rescuedAnimal',
  },
  {
    key: 'specialNeedsAnimal',
    label: 'Special needs animal',
    option: 'specialNeedsAnimal',
  },
  {
    key: 'groomer',
    label: 'Groomer',
    option: 'groomer',
  },
  {
    key: 'petNutritionist',
    label: 'Pet Nutritionist',
    option: 'petNutritionist',
  },
  {
    key: 'vetOrVetTech',
    label: 'Vet or Vet tech',
    option: 'vetOrVetTech',
  },
  {
    key: 'animalTrainer',
    label: 'Animal trainer',
    option: 'animalTrainer',
  },
];

export const sizeOptions = [
  { option: 'xs', label: 'X-Small', key: 'xs' },
  { option: 'sm', label: 'Small', key: 'sm' },
  { option: 'm', label: 'Medium', key: 'm' },
  { option: 'l', label: 'Large', key: 'l' },
  { option: 'xl', label: 'X-Large', key: 'xl' },
];

export const ratingOptions = [
  { option: 'level-0', label: 'Not Certified' },
  { option: 'level-1', label: 'Level One - Temperament Approved' },
  { option: 'level-2', label: 'Level Two - Animal Actor' },
  { option: 'level-3', label: 'Level Three - Advanced Animal Actor' },
  { option: 'level-4', label: 'Level Four - Elite Animal Actor' },
];

export const colorOptions = [
  { option: 'black', label: 'Black', key: 'black' },
  { option: 'white', label: 'White', key: 'white' },
  { option: 'greyOrSilver', label: 'Grey or Silver', key: 'greyOrSilver' },
  { option: 'beigeOrTan', label: 'Beige or Tan', key: 'beigeOrTan' },
  { option: 'orange', label: 'Orange', key: 'orange' },
  { option: 'red', label: 'Red or Pink', key: 'red' },
  { option: 'green', label: 'Green', key: 'green' },
  { option: 'blue', label: 'Blue', key: 'blue' },
  { option: 'purple', label: 'Purple', key: 'purple' },
  { option: 'yellow', label: 'Yellow', key: 'yellow' },
];

export const socialMediaLinks = [
  { value: 'facebook', label: 'Facebook', key: 'facebook', logo: FBLogo },
  { value: 'instagram', label: 'Instagram', key: 'instagram', logo: instaLogo },
  { value: 'tiktok', label: 'Tiktok', key: 'tiktok', logo: tiktokLogo },
  { value: 'youtube', label: 'Youtube', key: 'youtube', logo: utubeLogo },
  { value: 'pinterest', label: 'Pinterest', key: 'pinterest', logo: pinterslogo },
  { value: 'website', label: 'Website', key: 'website', logo: weblogo },
];

export const listingFields = [
  {
    key: 'listingCategory',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'castingCall', label: 'Casting Call' },
      { option: 'listing', label: 'Animals' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Search type',
      group: 'primary',
    },
    showConfig: {
      label: 'Search type',
      isDetail: true,
    },
    saveConfig: {
      label: 'Search type',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a type.',
    },
  },

  {
    key: 'location',

    filterConfig: {
      indexForSearch: true,
      label: 'Location',
      searchMode: 'has_all',
      group: 'primary',
    },
  },
  {
    key: 'keywords',

    filterConfig: {
      indexForSearch: true,
      label: 'Keywords',
      searchMode: 'has_all',
      group: 'secondary',
    },
  },

  {
    key: 'typeOfAnimal',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [],
    filterConfig: {
      indexForSearch: true,
      label: 'Type of animal',
      searchMode: 'has_all',
      group: 'primary',
    },
    showConfig: {
      label: 'Type of animal',
    },
    saveConfig: {
      label: 'Type of animal',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  {
    key: 'category',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [],
    filterConfig: {
      indexForSearch: true,
      label: 'Category',
      searchMode: 'has_any',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
    },
    saveConfig: {
      label: 'Category',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  {
    key: 'size',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: sizeOptions,
    filterConfig: {
      indexForSearch: true,
      label: 'Size',
      group: 'secondary',
    },
    showConfig: {
      label: 'Size',
      isDetail: true,
    },
    saveConfig: {
      label: 'Size',
      placeholderMessage: 'Choose…',
      isRequired: true,
      requiredMessage: 'You need to select a size.',
    },
  },

  {
    key: 'color',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: colorOptions,
    filterConfig: {
      indexForSearch: true,
      label: 'Color',
      group: 'secondary',
    },
    showConfig: {
      label: 'Color',
      isDetail: true,
    },
    saveConfig: {
      label: 'Color',
      placeholderMessage: 'Choose…',
      isRequired: true,
      requiredMessage: 'You need to select a color.',
    },
  },

  {
    key: 'traits',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: allTraitsForFilter,
    filterConfig: {
      indexForSearch: true,
      label: 'Traits',
      group: 'secondary',
    },
    showConfig: {
      label: 'Traits',
      isDetail: true,
    },
    saveConfig: {
      label: 'Traits',
      placeholderMessage: 'Choose…',
      isRequired: true,
      requiredMessage: 'You need to select a traits.',
    },
  },

  {
    key: 'ratings',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: ratingOptions,
    filterConfig: {
      indexForSearch: true,
      label: 'Certified Animal Actor Ratings',
      group: 'secondary',
    },
    showConfig: {
      label: 'Certified Animal Actor Ratings',
      isDetail: true,
    },
    saveConfig: {
      label: 'Certified Animal Actor Ratings',
      placeholderMessage: 'Choose…',
      isRequired: true,
      requiredMessage: 'You need to select a ratings.',
    },
  },
  // {
  //   key: 'category',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'castingCall', label: 'Casting Call' },
  //     { option: 'electric-bikes', label: 'Electric bikes' },
  //     { option: 'mountain-bikes', label: 'Mountain bikes' },
  //     { option: 'childrens-bikes', label: "Children's bikes" },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Category',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Category',r
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Category',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a category.',
  //   },
  // },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields This is relevant only to listings using default-inquiry process atm.
 *                        It contains price: true/false value to indicate, whether price should be shown.
 *                        If defaultListingFields.price is not explicitly set to _false_, price will be shown.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;

// export const listingCategories = [
//   { key: 'entertainmentAnimals', label: 'Entertainment Animals', value: 'entertainmentAnimals' },
//   {
//     key: 'animalContentCreators',
//     label: 'Animal Content Creators',
//     value: 'animalContentCreators',
//   },
//   { key: 'workingAnimals', label: 'Working Animals', value: 'workingAnimals' },
//   { key: 'animalProfessionals', label: 'Animal Professionals', value: 'animalProfessionals' },
// ];

export const actingAnimals = [
  { label: 'Any', value: 'any' },
  { label: 'Dog', value: 'dog' },
  { label: 'Cat', value: 'cat' },
  {
    label: 'Farm Animals',
    value: 'farmAnimals',
    subcategories: [
      { label: 'Horse/Donkey', value: 'horseDonkey' },
      { label: 'Cattle', value: 'cattle' },
      { label: 'Goats/Sheep', value: 'goatsSheep' },
      { label: 'Pigs', value: 'pigs' },
      { label: 'Bunny/Rabbit', value: 'bunnyRabbit' },
      { label: 'Chicken/Ducks', value: 'chickenDucks' },
    ],
  },
  {
    label: 'Rodents',
    value: 'rodents',
    subcategories: [
      { label: 'Rats/Mice', value: 'ratsMice' },
      { label: 'Guinea Pig/Chinchilla', value: 'guineaPigChinchilla' },
      { label: 'Other Rodents', value: 'otherRodents' },
    ],
  },
  {
    label: 'Reptiles',
    value: 'reptiles',
    subcategories: [
      { label: 'Crocodilie', value: 'crocodilie' },
      { label: 'Snakes', value: 'snakes' },
      { label: 'Lizards', value: 'lizards' },
      { label: 'Turtles/Tortoises', value: 'turtlesTortoises' },
    ],
  },
  {
    label: 'Birds',
    value: 'birds',
    subcategories: [
      { label: 'Exotic - Parrot, Macaw etc', value: 'exoticBirds' },
      { label: 'Domestic - Raven, Seagull, Dove etc', value: 'domesticBirds' },
      { label: 'Birds of prey - Eagles, Raptors, Owls etc', value: 'birdsOfPrey' },
      { label: 'Large birds - Ostrich/ Emu', value: 'largeBirds' },
    ],
  },
  {
    label: 'Fish/Amphibians',
    value: 'fish_amphibians',
    subcategories: [
      { label: 'Fish', value: 'fish' },
      { label: 'Frog/Toad', value: 'frogToad' },
      { label: 'Salamanders', value: 'salamanders' },
      { label: 'Caecilians - Worms', value: 'caeciliansWorms' },
    ],
  },
  { label: 'Invertebrates/Insects', value: 'invertebrates_insects' },
  { label: 'Other', value: 'other' },
  // Add more categories as needed
];

export const actingAnimalsCategory = [
  { label: 'Any', value: 'any' },
  {
    label: 'Entertainment Animals',
    value: 'entertainmentAnimals',
    subcategories: [
      {
        label: 'Certified Animal Actors',
        value: 'certifiedAnimalActors',
        traits: [
          {
            option: 'beautifulModelAnimal',
            label: 'Beautiful model animal',
            key: 'beautifulModelAnimal',
          },
          {
            option: 'scaryLookingIntimidating',
            label: 'Scary looking or intimidating',
            key: 'scaryLookingIntimidating',
          },
          {
            option: 'cute',
            label: 'Cute',
            key: 'cute',
          },
          {
            option: 'uniqueFunnyLookingHairless',
            label: 'Unique, funny looking, or hairless',
            key: 'uniqueFunnyLookingHairless',
          },
          {
            option: 'fluffy',
            label: 'Fluffy',
            key: 'fluffy',
          },
          {
            option: 'athleticBuild',
            label: 'Athletic build',
            key: 'athleticBuild',
          },
          {
            option: 'familyFriendlyLook',
            label: 'Family friendly look',
            key: 'familyFriendlyLook',
          },
        ],
      },
      {
        label: 'Therapy Animals',
        value: 'therapyAnimals',
        traits: [
          {
            option: 'therapyDogs',
            label: 'Therapy Dogs',
            key: 'therapyDogs',
          },
          {
            option: 'equineAssistedTherapy',
            label: 'Equine Assisted Therapy',
            key: 'equineAssistedTherapy',
          },
          {
            option: 'animalAssistedTherapyActivities',
            label: 'Animal Assisted Therapy & Activities',
            key: 'animalAssistedTherapyActivities',
          },
        ],
      },
      {
        label: 'Educational Animals',
        value: 'educationalAnimals',
        traits: [
          {
            option: 'demonstrationsWorkshops',
            label: 'Demonstrations and Workshops',
            key: 'demonstrationsWorkshops',
          },
          {
            option: 'wildlifeEducationAmbassadorConservationPrograms',
            label: 'Wildlife Education Ambassador & Conservation Programs',
            key: 'wildlifeEducationAmbassadorConservationPrograms',
          },
        ],
      },
      {
        label: 'Animals For Events',
        value: 'animalsForEvents',
        traits: [
          {
            option: 'animalInteractionPettingZoo',
            label: 'Animal Interaction (Petting Zoo)',
            key: 'animalInteractionPettingZoo',
          },
          {
            option: 'ponyRides',
            label: 'Pony Rides',
            key: 'ponyRides',
          },
          {
            option: 'trickAnimalShows',
            label: 'Trick Animal Shows',
            key: 'trickAnimalShows',
          },
          {
            option: 'animalAssistedYoga',
            label: 'Animal Assisted Yoga',
            key: 'animalAssistedYoga',
          },
        ],
      },
    ],
  },
  {
    label: 'Animal Professional',
    value: 'animalProfessional',
    subcategories: [
      {
        label: 'Certified Animal Wrangler',
        value: 'certifiedAnimalWrangler',
        traits: [],
      },
      {
        label: 'Animal Care and Welfare',
        value: 'animalCareWelfare',
        traits: [],
        // [
        //   {
        //     option: 'animalHandlers',
        //     label: 'Animal Handlers',
        //     key: 'animalHandlers',
        //   },
        //   {
        //     option: 'animalCaretakersWalkers',
        //     label: 'Animal Caretakers and Walkers',
        //     key: 'animalCaretakersWalkers',
        //   },
        //   {
        //     option: 'animalNutritionists',
        //     label: 'Animal Nutritionists',
        //     key: 'animalNutritionists',
        //   },
        //   {
        //     option: 'equineDentistsFarriers',
        //     label: 'Equine Dentists and Farriers',
        //     key: 'equineDentistsFarriers',
        //   },
        //   {
        //     option: 'animalWelfareInspectors',
        //     label: 'Animal Welfare Inspectors',
        //     key: 'animalWelfareInspectors',
        //   },
        // ],
      },
      {
        label: 'Animal Training and Behavior',
        value: 'animalTrainingBehavior',
        traits: [],
        // [
        //   {
        //     option: 'filmProductionWranglers',
        //     label: 'Film and Production Wranglers',
        //     key: 'filmProductionWranglers',
        //   },
        //   {
        //     option: 'animalBehaviorist',
        //     label: 'Animal Behaviorist',
        //     key: 'animalBehaviorist',
        //   },
        //   {
        //     option: 'animalTrainers',
        //     label: 'Animal Trainers',
        //     key: 'animalTrainers',
        //   },
        //   {
        //     option: 'animalCommunicators',
        //     label: 'Animal Communicators',
        //     key: 'animalCommunicators',
        //   },
        // ],
      },
      {
        label: 'Animal Transportation',
        value: 'animalTransportation',
        traits: [],
        // [
        //   {
        //     option: 'wildlifeTransporters',
        //     label: 'Wildlife Transporters',
        //     key: 'wildlifeTransporters',
        //   },
        //   {
        //     option: 'petRelocationExperts',
        //     label: 'Pet Relocation Experts',
        //     key: 'petRelocationExperts',
        //   },
        // ],
      },
      {
        label: 'Animal Photographers/Videographers',
        value: 'animalPhotographersVideographers',
        traits: [],
        // [
        //   {
        //     option: 'animalPhotographers',
        //     label: 'Animal Photographers',
        //     key: 'animalPhotographers',
        //   },
        //   {
        //     option: 'animalVideographers',
        //     label: 'Animal Videographers',
        //     key: 'animalVideographers',
        //   },
        // ],
      },
    ],
  },
  {
    label: 'Working Animals',
    value: 'workingAnimals',
    subcategories: [
      {
        label: 'Guard Animals',
        value: 'guardAnimals',
        traits: [
          {
            option: 'livestockGuardianDogs',
            label: 'Livestock Guardian Dogs',
            key: 'livestockGuardianDogs',
          },
          {
            option: 'personalProtectionDogs',
            label: 'Personal Protection Dogs',
            key: 'personalProtectionDogs',
          },
        ],
      },
      {
        label: 'Search and Detection Animals',
        value: 'searchDetectionAnimals',
        traits: [
          {
            option: 'searchAndRescueDogs',
            label: 'Search and Rescue Dogs',
            key: 'searchAndRescueDogs',
          },
          {
            option: 'detectionDogs',
            label: 'Detection Dogs (Drugs, Explosives, Diseases)',
            key: 'detectionDogs',
          },
          {
            option: 'conservationWildlifeTrackingAnimals',
            label: 'Conservation Wildlife Tracking Animals',
            key: 'conservationWildlifeTrackingAnimals',
          },
        ],
      },
      {
        label: 'Agricultural Services Animals',
        value: 'agriculturalServicesAnimals',
        traits: [
          {
            option: 'grazingWeedManagementAnimal',
            label: 'Grazing and Weed Management Animal',
            key: 'grazingWeedManagementAnimal',
          },
          {
            option: 'pestControlAnimals',
            label: 'Pest Control Animals (Birds and Dogs) for Agriculture',
            key: 'pestControlAnimals',
          },
          {
            option: 'agriculturalManagement',
            label: 'Agricultural Management',
            key: 'agriculturalManagement',
          },
        ],
      },
    ],
  },

  {
    label: 'Animal Content Creator',
    value: 'animalContentCreator',
    traits: traitsForAnimalContentCreator,
    subcategories: [
      {
        label: 'Pet Outdoor Activities & Traveling',
        value: 'petOutdoorActivitiesTraveling',
        traits: traitsForAnimalContentCreator,
      },
      {
        label: 'Pet Lifestyle, Home and Family',
        value: 'petLifestyleHomeFamily',
        traits: traitsForAnimalContentCreator,
      },
      {
        label: 'Pet Fashion and Styling',
        value: 'petFashionStyling',
        traits: traitsForAnimalContentCreator,
      },
      {
        label: 'Pet Comedy & Trends',
        value: 'petComedyTrends',
        traits: traitsForAnimalContentCreator,
      },
      {
        label: 'Pet Health and Wellness',
        value: 'petHealthWellness',
        traits: traitsForAnimalContentCreator,
      },
      {
        label: 'Pet Training and Education',
        value: 'petTrainingEducation',
        traits: traitsForAnimalContentCreator,
      },
      {
        label: '501(c)(3) Rescue Organization or Animal Sanctuary',
        value: 'rescueOrganizationAnimalSanctuary',
      },
    ],
  },
];

export const allowedAddonForOthers = [
  // { label: 'Insurance', value: 'insurance', key: 'insurance' },
  { label: 'Legalities', value: 'legalities', key: 'legalities' },
  { label: 'Transportation fees', value: 'transportationFee', key: 'transportationFee' },
  { label: 'Overtime', value: 'overtime', key: 'overtime' },
  // {
  //   label: 'Permits, rental, documentation for set work, etc',
  //   value: 'permitsRentalDocumentationForSetWorkEtc',
  //   key: 'permitsRentalDocumentationForSetWorkEtc',
  // },
  // { label: 'Animal wrangler fees', value: 'animalWranglerFees', key: 'animalWranglerFees' },
  // { label: 'Prep Days', value: 'prepDays', key: 'prepDays' },
  // { label: 'Health Certificate', value: 'healthCertificate', key: 'healthCertificate' },
  // { label: 'Union local 399 Member', value: 'unionLocal399Member', key: 'unionLocal399Member' },
];

export const allowedAddOnForContentCreator = [
  { label: 'Usage Rights', value: 'usageRights', key: 'usageRights' },
  { label: 'Social media posting', value: 'socialMediaPosting', key: 'socialMediaPosting' },
  { label: 'Exclusivity', value: 'exclusivity', key: 'exclusivity' },
  // {
  //   label: 'Content Creation ONLY shot at home',
  //   value: 'contentCreationOnlyShotAtHome',
  //   key: 'contentCreationOnlyShotAtHome',
  // },
];

const regexPhoneNumber = /\b\d{10}\b|\b\d{3}-\d{3}-\d{4}\b|\bnumber\b|@/g;
const regexSocialMediaHandle = /@[A-Za-z0-9_]+/g;
const regexUrl = /(?:https?:\/\/|www\.)\S+/gi;
const regexSocialMediaKeywords = /\b(WhatsApp|messenger|Direct message|Telegram|facebook|Signal|outlook|yahoo|id|linkedin|Skype|Slack|Discord|Zoom|Teams|Webex|Viber|LINE|FaceTime)\b/gi;
const regexContactKeywords = /(call me|phone|sms|message|dm|link|meeting|meet|contact|google chat|zoom|facebook id|wp id|instagram id|email id|twitter id|skype|@|email|mail|call|address|ssn|social security|fika|telefoni|fetu’utaki|fetuutaki|ta mai|ta)/gi;

export const marketplaceRegexPatterns = new RegExp(
  regexPhoneNumber.source +
    '|' +
    regexSocialMediaHandle.source +
    '|' +
    regexUrl.source +
    '|' +
    regexSocialMediaKeywords.source +
    '|' +
    regexContactKeywords.source,
  'gi'
);
