/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../../components';

import css from './TopbarMobileMenu.module.css';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    history,
    location,
  } = props;
  const routes = useRouteConfiguration();

  const userType = currentUser?.attributes?.profile?.publicData?.userType;
  const [clicked, setClicked] = useState(false);
  const [currType, setcurrType] = useState(
    (typeof window !== 'undefined' && window.localStorage.getItem('switchedType')) || userType
  );
  useEffect(() => {
    if (clicked) {
      const isDashboard = location?.pathname?.includes('/dashboard/');
      const route = isDashboard
        ? 'Dashboard'
        : currType === 'provider'
        ? 'SellerLandingPage'
        : 'LandingPage';
      history.push(
        createResourceLocatorString(route, routes, isDashboard ? { tab: 'listings' } : {})
      );
    }
  }, [currType]);
  const user = ensureCurrentUser(currentUser);

  const isProvider = userType === 'provider';
  const isCustomer = userType === 'customer';
  const providerCategory = currentUser?.attributes?.profile?.publicData?.providerCategory;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            {signup}
            {login}
            <NamedLink
              className={classNames(css.loginLink, currentPageClass('FaqPage'))}
              name="FAQPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.faq" />
            </NamedLink>
            <NamedLink
              className={classNames(css.loginLink, currentPageClass('About'))}
              name="AboutUs"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.about" />
            </NamedLink>
            <NamedLink className={css.loginLink} name="PostListingGuide">
              <FormattedMessage id="TopbarDesktop.createListing" />
            </NamedLink>
            <NamedLink className={css.loginLink} name="NewCastingCallPage">
              <FormattedMessage id="TopbarDesktop.postCastingCall" />
            </NamedLink>
          </div>
        </div>
        {/* <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="PostListingGuide">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div> */}
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;

  const switchButtonMaybe = (
    <button
      className={css.createNewListingLink}
      type="button"
      onClick={e => {
        typeof window !== 'undefined' &&
          window.localStorage.setItem(
            'switchedType',
            currType === 'provider' ? 'customer' : 'provider'
          );
        setClicked(true);
        setcurrType(currType === 'provider' ? 'customer' : 'provider');
      }}
    >
      Switch to {currType === 'provider' ? 'Buyer' : 'Seller'}
    </button>
  );
  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        {(isProvider && currType === 'customer') || isCustomer ? (
          // <NamedLink className={css.createListingLink} name="PostCastingCall">
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('NewCastingCallPage'))}
            name="NewCastingCallPage"
          >
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.postCastingCall" />
            </span>
          </NamedLink>
        ) : null}

        {(isProvider && currType !== 'customer') || currType === 'provider' ? (
          // <NamedLink
          //   className={classNames(css.createListingLink, css.animllstbtn)}
          //   name="PostListing"
          // >
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('PostListingGuide'))}
            name="PostListingGuide"
          >
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.createListing" />
            </span>
          </NamedLink>
        ) : null}
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: 'sales' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {/* <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink> */}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
          to={
            isProvider
              ? { search: `type=provider&category=${providerCategory}` }
              : { search: `type=customer` }
          }
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('Dashboard'))}
          name="Dashboard"
          params={{ tab: 'listings' }}
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.dashboardlink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="FavouritesPage"
        >
          Favourites
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('FaqPage'))}
          name="FAQPage"
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.faq" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('About'))}
          name="AboutUs"
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.about" />
        </NamedLink>
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>{switchButtonMaybe}</div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
